.AccessMenuDesktop {
  &__big-nav-desktop {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    p {
      color: var(--white);
      font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
      font-size: 1rem;
      line-height: 1.22;
    }
  }

  &__fast-menu {
    width: 100%;
    display: flex;
    flex: nowrap;
    justify-content: space-between;
    padding: 0;

    a {
      width: 100%;
      border-left: 0.05px solid var(--white);

      &:hover {
        border-left: 0.05px solid var(--primary);

        & + a {
          border-left: 0.05px solid var(--primary);
        }
      }

      &:first-of-type {
        border-left: 0.05px solid var(--primary);
        border-right: 0.05px solid var(--primary);

        & + a:hover {
          border-left: 0.05px solid var(--primary);
        }
      }

      &:last-of-type {
        border-right: 0.05px solid var(--white);

        &:hover {
          border-left: 0.05px solid var(--primary);
          border-right: 0.05px solid var(--primary);
        }
      }
    }
  }
}
