.cardIcon {
  &.card-icon {
    width: 100%;
    height: 113px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    place-content: center space-between;
    align-items: center;
    color: var(--white);

    &.active {
      background: var(--success);
      color: var(--green-dark-200);
      cursor: pointer;

      &:hover {
        background: #9fbe43;
      }

      .icon {
        fill: var(--green-dark-200);

        path {
          fill: var(--green-dark-200);

          &:last-of-type {
            fill: var(--green-dark-200);
          }
        }
      }
    }

    &:hover {
      background: var(--success);
      color: var(--green-dark-200);
      cursor: pointer;

      .icon {
        fill: var(--green-dark-200);

        path {
          fill: var(--green-dark-200);

          &:last-of-type {
            fill: var(--green-dark-200);
          }
        }
      }
    }

    img,
    i {
      width: 40px;
      height: auto;
      font-size: 40px;
      color: var(--transparent);
    }

    .icon {
      fill: var(--white);

      path {
        fill: var(--white);

        &:last-of-type {
          fill: var(--white);
        }
      }
    }

    p {
      font-family: 'UnimedSans-2020-SemiBold', Arial, sans-serif;
      font-size: 1rem;
      white-space: nowrap;
    }
  }
}
