.AccessMenuMobile {
  &.fast-menu {
    .cta {
      width: 100%;
      height: 48px;
      background: var(--transparent);
      border: none;
      color: var(--white);
      font-family: "UnimedSlab-2020-SemiBold", Arial, sans-serif;
      font-size: 1.125rem;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
